<template>
  <div>
    <v-card class="pa-4">
      <p class="text-2xl ma-5">{{ title }}</p>
      <v-row class="mx-1 my-3">
        <v-card-text>
          <v-form ref="FormCreate">
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-model="selectedSite"
                  :items="sites"
                  item-text="domain"
                  item-value="id"
                  label="Site"
                  outlined
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-if="varNameType == 'month_number'"
                  v-model="varName"
                  :items="months"
                  item-text="text"
                  item-value="value"
                  label="Mois"
                  outlined
                >
                </v-autocomplete>
                <v-text-field
                  v-else
                  v-model="varName"
                  label="var_name"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12">
                <v-text-field
                  v-if="!onlyField || onlyField == 'var_value'"
                  v-model="inputValue"
                  label="Valeur"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="!onlyField || onlyField == 'var_value_int'"
                  v-model="inputValueFloat"
                  label="Chiffre entier"
                  outlined
                ></v-text-field>
                <v-text-field
                  v-if="!onlyField || onlyField == 'var_value_float'"
                  v-model="inputValueFloat"
                  label="Chiffre (décimale avec un point et non une virgule)"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <!-- todo input var_value_int-->
              </v-col>
              <v-col cols="12" sm="12">
                <!-- todo input var_value-->
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn color="primary" class="me-3 mt-3" @click.native="save"
                  >Sauvegarder</v-btn
                >
                <v-btn
                  color="secondary"
                  outlined
                  class="mt-3"
                  @click.native="$emit('openDialogFunction', false)"
                >
                  Annuler
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import axios from "@axios";
import { monthsFrArray } from "@/utils/dates";

export default {
  name: "CreateGroupModal",
  props: {
    title: {
      type: String,
      required: false,
      default: "Sauvegarder une variable meta",
    },
    openDialogIsOpen: {
      type: Boolean,
      required: true,
    },
    onlyField: {
      type: String, // one of "var_value" "var_value_int" "var_value_float"
      required: false,
    },
    varNameType: {
      type: String, // one of "month_number"
      required: false,
    },
    varGroup: {
      type: String,
      required: true,
    },
    departmentId: {
      type: Number, // 2 = programmatic
      required: true,
    },
  },
  data() {
    return {
      months: [],
      selectedSite: null,
      varName: null,
      inputValue: null,
      inputValueInt: null,
      inputValueFloat: null,
      sites: [],
    };
  },
  async created() {
    this.sites = await this.$store.dispatch("common/getSiteList");
    let months = [];
    for (const [i, value] of monthsFrArray.entries()) {
      let monthNb = i + 1;
      monthNb = monthNb < 10 ? `0${monthNb}` : `${monthNb}`;
      months.push({
        text: value,
        value: monthNb,
      });
    }
    this.months = months;
  },
  methods: {
    async save() {
      const isFormValid = this.$refs.FormCreate.validate();
      if (!isFormValid) return;

      const params = {
        department_id: this.departmentId,
        site_id: parseInt(this.selectedSite),
        var_group: this.varGroup,
        var_name: this.varName,
        var_value: this.inputValue,
        var_value_int: this.inputValueInt,
        var_value_float: this.inputValueFloat,
      };
      // var_value_float: item[this.varName],

      const response = await axios.put("/metas/save", params);
      if (response.status === 200) {
        this.$emit("openDialogFunction", false, response);
      } else {
        alert("An error occurred.");
      }
    },
  },
  watch: {
    openDialogIsOpen(visible) {
      if (visible) {
        this.name = "";
        this.code = "";
        this.$refs.FormCreate.resetValidation();
      }
    },
  },
  computed: {},
};
</script>
